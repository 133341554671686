// @flow
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    Grid,
    Switch,
    TextField,
} from '@material-ui/core';

import { selectUserId } from 'txp-core';
import type { FollowerEditState } from '../Redux/FollowerEditActions';
import FollowerTaskList from './FollowerTaskList';
import UserDetails from './UserDetails';
import Images from '../Themes/Images';
import { getContacts, resetChatroomDetails } from '../Redux/ChatroomActions';
import { filterPeople } from '../Redux/ChatListActions';
import { getTeams } from '../Redux/TeamActions';
import AddPeople from './AddPeople';
import styles from './Styles/CasePreferencesStyles';
import type { UserProfile } from '../Utils/types';

type Props = {
    followerGroup: FollowerEditState,
    onUpdateGroup: (followerGroup: FollowerEditState) => *,
};

export default function CasePreferencesFollower(props: Props) {
    const { followerGroup, onUpdateGroup, } = props;
    const { followerName, } = followerGroup;
    const dispatch = useDispatch();

    const newDonorId = useSelector((state) => state.donor.openDonorId);
    // Needed for Add People page
    const teams = useSelector((state) => state.team.teams);
    const teamsLoading = useSelector((state) => state.loading.teams);
    const memberId = useSelector((state) => selectUserId(state.auth));
    const isConnected = useSelector((state) => state.chatList.socketStatus === 'connected');
    const contactsOrder = useSelector((state) => state.chatroom.contactsOrder);
    const contactsAreLoaded = useSelector((state) => state.chatroom.contactsAreLoaded);
    const contactsMap = useSelector((state) => state.chatroom.contactsMap);
    const peopleFilter = useSelector((state) => state.chatList.peopleFilter);
    const singleUserAdded = useSelector((state) => state.chatroom.singleUserAdded);
    const newUserInvited = useSelector((state) => state.chatroom.newUserInvited);
    const addSingleUserError = useSelector((state) => state.chatroom.addSingleUserError);
    const inviteNewUserError = useSelector((state) => state.chatroom.inviteNewUserError);

    const avatars = useSelector((state) => state.chatList.avatars);

    const [showAddPeople, setShowAddPeople] = useState<boolean>(false);

    // create a member map for AddPeople
    const followerMemberMap = {};
    for (let i = 0; i < followerGroup.pendingUsers.length; i += 1) {
        const user = followerGroup.pendingUsers[i];
        followerMemberMap[user.userId] = user;
    }

    const onShowAddPeople = () => {
        dispatch(getContacts());
        dispatch(getTeams());

        // Toggle the show add people flag
        setShowAddPeople(true);
    };

    const updateFollowerGroupTasks = (updatedTasks) => {
        const newGroup = { ...followerGroup, };
        newGroup.taskIds = updatedTasks;
        onUpdateGroup(newGroup);
    };

    const onRemoveMemberUser = (userId: number) => {
        const newUsers = [...followerGroup.pendingUsers];
        const idxToRemove = newUsers.findIndex((user) => user.userId === userId);
        newUsers.splice(idxToRemove, 1);
        followerGroup.pendingUsers = newUsers;
        onUpdateGroup(followerGroup);
    };

    const onAddUsers = (users: Array<UserProfile>) => {
        const newUsers = followerGroup.pendingUsers.concat(users);
        followerGroup.pendingUsers = newUsers;
        onUpdateGroup(followerGroup);
        setShowAddPeople(false);
    };

    const onChangeFollowerCanUpdate = (canUpdate: boolean) => {
        followerGroup.canUpdate = canUpdate;
        onUpdateGroup(followerGroup);
    };

    const onFollowerNameChange = (newName: string) => {
        followerGroup.followerName = newName;
        onUpdateGroup(followerGroup);
    };

    const pendingUsersCopy = [...followerGroup.pendingUsers];
    const hasUndefinedName = pendingUsersCopy.some((user) => !user.firstName || !user.lastName);
    let sortedPendingUsers;
    if (hasUndefinedName) {
        sortedPendingUsers = pendingUsersCopy;
    } else {
        sortedPendingUsers = pendingUsersCopy.sort((a, b) => {
            if (a.firstName == null || b.firstName == null) {
                return 0;
            }
            const firstNameComparison = a.firstName.localeCompare(b.firstName, undefined, { sensitivity: 'base', });
            if (firstNameComparison !== 0) {
                return firstNameComparison;
            }
            if (a.lastName == null || b.lastName == null) {
                return 0;
            }
            return a.lastName.localeCompare(b.lastName, undefined, { sensitivity: 'base', });
        });
    }

    return (
        <Grid container>
            <Grid container>
                <TextField
                    disabled={false}
                    color="primary"
                    label="Follower Group Name:"
                    type="text"
                    InputLabelProps={{ shrink: true, }}
                    value={followerName}
                    onChange={(event) => onFollowerNameChange(event.target.value)}
                />
            </Grid>
            <div>
                <Switch
                    color="primary"
                    checked={followerGroup.canUpdate}
                    onChange={(event) => onChangeFollowerCanUpdate(event.target.checked)}
                />
                <span>Group can update tasks</span>
            </div>
            <Grid container>
                <Grid item sm={12} md={3}>
                    <div style={styles.gridLabel}>Current Tasks</div>
                    <FollowerTaskList
                        donorId={newDonorId}
                        followerEdit={followerGroup}
                        onSetFollowerTasks={(updatedTasks) => updateFollowerGroupTasks(updatedTasks)}
                    />
                </Grid>
                <Grid item sm={12} md={4}>
                    <div style={styles.gridLabel}>Current Members</div>
                    <div style={styles.followerMembersWrapper}>
                        <div
                            style={styles.addPeopleButton}
                            className="lineButtons"
                            role="button"
                            tabIndex="0"
                            onKeyDown={onShowAddPeople}
                            onClick={onShowAddPeople}
                            data-tip="Add users to this donor"
                            data-delay-show="500"
                            data-effect="solid"
                        >
                            Add people
                        </div>
                        {sortedPendingUsers.map((user) => (
                            <UserDetails
                                key={user.userId}
                                membershipStatus="Present"
                                startDate=""
                                endDate=""
                                userProfile={user}
                                avatar={avatars[user.userId] || Images.circledDefaultProfile}
                                displayButton
                                doNotDisturb={false}
                                displayEmail
                                wrapEmail={false}
                                isConnected
                                onMemberClick={() => onRemoveMemberUser(user.userId)}
                                // Not relevant to follower members
                                addManager={() => {}}
                                removeManager={() => {}}
                                showMemberProfile={() => {}}
                                isTeamList
                            />
                        ))}
                    </div>
                </Grid>
                <Grid item sm={12} md={5}>
                    {showAddPeople ? (
                        <>
                            <div style={styles.gridLabel}>Add New Members</div>
                            <div style={styles.addPeople}>
                                <AddPeople
                                    memberId={memberId}
                                    contactsOrder={contactsOrder}
                                    contactsMap={contactsMap}
                                    teams={teams}
                                    teamsLoading={teamsLoading}
                                    contactsAreLoaded={contactsAreLoaded}
                                    singleUserAdded={singleUserAdded}
                                    newUserInvited={newUserInvited}
                                    addSingleUserError={addSingleUserError}
                                    inviteNewUserError={inviteNewUserError}
                                    avatars={avatars}
                                    members={followerMemberMap}
                                    peopleFilter={peopleFilter}
                                    createChatroom={false}
                                    isConnected={isConnected}
                                    onHideAddPeople={() => setShowAddPeople(false)}
                                    onAddUsers={(users) => onAddUsers(users)} // Add the selected user to the follower group
                                    inviteNewUser={() => {}} // Don't currently allow this, maybe just go right to the add External User Screen?
                                    clearMessage={() => dispatch(resetChatroomDetails())}
                                    filterPeople={(filter: string) => dispatch(filterPeople(filter))}
                                    backButtonLabel="Close"
                                    hideTitle
                                />
                            </div>
                        </>
                    ) : null}
                </Grid>
            </Grid>
        </Grid>
    );
}
